import WorkspaceData from '../WorkspaceData';
import router from "@/router";

export default class DemoWorkspace extends WorkspaceData {
  constructor() {
    super();
    this.customerId = 'demo';
    this.workspace.animals = [];
    console.log('Custom workspace loaded: demo');
  }

  getRenderAllowance() {
    return this.workspace.animals.length > 0;
  }

  getRenderQuery() {
    if(this.getRenderAllowance()) {
      return `name=${this.workspace.animals[0]}&seed=${this.workspace.seed}&customerCode=${this.customerId}`;
    }
    this.dispatchEvent( {
      type: 'toast-msg',
      message: {
        type: 'warning',
        text:`missing animal`,
      },
    });

    return false;
  }

  async search(query) {
    const response = await fetch(`/api/v1/demo/pets?query=${query}`);
    const responseJson = await response.json();

    switch (response.status) {
      case 200:
        return responseJson;
      case 401:
        await router.push({name:'Login'});
        break;
      default:
        this.dispatchEvent( {
          type: 'toast-msg',
          message: {
            type: 'error',
            text: `(${response.status}): ${response.statusText}`,
          },
        });
    }
  }

  addAnimalToList(name) {
    this.workspace.animals.unshift(name);
    if (this.workspace.animals.length > 15) {
      this.workspace.animals.pop();
    }
    this.renderTemplate();
  }
}